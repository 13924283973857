<template>
  <div class="app-container MoreDetail">
    <!-- 订单详情 -->
    <div class="order-Detail">
      <el-button class="fr" size="small" type="primary" @click="$router.back()">返回</el-button>
      <div class="order-Detail-hd">订单详情</div>
      <div>订单号：{{ orderCode }}</div>
    </div>
    <!-- 流程进度 -->
    <div class="flow">
      <div class="flow-state">流程进度：</div>
      <div>
        <el-steps :active="orderStatusActive" align-center>
          <el-step
            v-for="{ orderStatus, nodeTime } in orderStatus"
            :key="orderStatus"
            :title="orderStatus"
            :description="nodeTime"
          ></el-step>
        </el-steps>
      </div>
    </div>
    <!-- 付款信息 -->
    <div class="payinfo">
      <div class="payinfo-info">付款信息</div>
      <div class="payinfo-up">
        <div>货款：{{ paymentGoods }}</div>
        <div>运费：{{ freight }}</div>
        <div>实付总额：{{ realAmount }}</div>
        <div></div>
      </div>
      <div class="payinfo-down">
        <div>付款时间：{{ paymentTime }}</div>
        <div>支付方式：{{ getPaymentMethod(tabledata) }}</div>
        <div>所属支付订单号：{{ paymentCode }}</div>
        <div></div>
      </div>
    </div>
    <!-- 商品明细 -->
    <div class="productDetail">
      <CommonTable height="400px" :selection="false" :cols="cols" :infoData="CommonTabledata"> </CommonTable>
    </div>
  </div>
</template>

<script>
import { cols } from './cols'
import { initDataMixin } from '@/mixins'
import { uniq } from 'lodash'
import { accAdd, accSub, parsePrice } from '@/utils'
import { PAYMENT_AMOUNT_ALL, PAYMENT_AMOUNT_PRODUCT, PAYMENT_AMOUNT_FREIGHT } from '@/utils/constant/paymentConst'

export default {
  mixins: [initDataMixin],
  data() {
    return {
      url: '/externaladmin/orderService/orderItem/paymentOrderItem',
      cols,
      tabledata: {},
      query: {
        externalOrderId: this.$route.query.orderId
      }
    }
  },
  methods: {
    accAdd,
    initCallBack(res) {
      this.tabledata = res.detail
    },
    getPaymentMethod({ paymentMethod, freightPaymentMethod }) {
      return uniq([paymentMethod, freightPaymentMethod].filter(Boolean)).join('、')
    }
  },
  computed: {
    paymentOrderData() {
      const data = sessionStorage.getItem('paymentOrderData')
      return JSON.parse(data)
    },
    paymentAmountType({ $route }) {
      return $GET($route, 'query.paymentAmountType', 0)
    },
    paymentGoods({ tabledata, paymentOrderData, paymentAmountType }) {
      if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
        return parsePrice(paymentOrderData.paymentProduct)
      }
      if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
        return ''
      }
      return parsePrice(accSub(paymentOrderData.internalTotalPrice, paymentOrderData.couponAmount))
    },
    freight({ tabledata, paymentOrderData, paymentAmountType }) {
      if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
        return ''
      }
      if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
        return parsePrice(paymentOrderData.paymentFreight)
      }
      return parsePrice(paymentOrderData.paymentFreight)
    },
    realAmount({ tabledata, paymentOrderData, paymentAmountType }) {
      if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
        return parsePrice(paymentOrderData.paymentProduct)
      }
      if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
        return parsePrice(paymentOrderData.paymentFreight)
      }
      return parsePrice(
        accSub(
          accAdd(paymentOrderData.internalTotalPrice, paymentOrderData.paymentFreight),
          paymentOrderData.couponAmount
        )
      )
    },

    paymentTime({ tabledata, paymentOrderData, paymentAmountType }) {
      if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
        return paymentOrderData.paymentProductTime
      }
      if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
        return paymentOrderData.paymentFreightTime
      }
      return paymentOrderData.paymentProductTime
    },
    paymentCode({ tabledata, paymentOrderData, paymentAmountType }) {
      if (paymentAmountType === PAYMENT_AMOUNT_PRODUCT) {
        return paymentOrderData.paymentProductCode
      }
      if (paymentAmountType === PAYMENT_AMOUNT_FREIGHT) {
        return paymentOrderData.paymentFreightCode
      }
      return paymentOrderData.paymentProductCode
    },
    CommonTabledata() {
      return this.tabledata.paymentOrderItemDTOList
    },
    orderCode() {
      return this.tabledata.orderCode
    },
    orderStatus() {
      return this.tabledata.orderStatus
    },
    orderStatusActive({ orderStatus }) {
      return orderStatus?.filter((v) => {
        return v.nodeTime !== null
      }).length
    }
  },
  async created() {}
}
</script>

<style lang="scss" scoped>
.MoreDetail {
  font-size: 14px;
  // 订单详情
  .order-Detail {
    margin-bottom: 50px;
    .order-Detail-hd {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  // 流程进度
  .flow {
    margin-bottom: 50px;
    .flow-state {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
  // 付款信息
  .payinfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .payinfo-info {
      font-size: 16px;
      margin-bottom: 20px;
    }
    .payinfo-up,
    .payinfo-down {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      div {
        flex: 1;
      }
    }
  }
  // 商品明细
  .productDetail {
    height: 400px;
  }
}
</style>
